import React from 'react'

function Footer() {
    return (
        <footer class="footer">



            <div class="center footer-box">
                <h2>Contact Info</h2>
                <br />
                <p class="footerphone">203-402-9550</p>
                <p class="footeremail">dcarrano72@gmail.com</p>


            </div>




        </footer>
    )
}

export default Footer