import React from 'react'
import { motion } from 'framer-motion'

function Form() {

    const formVariants = {
        hidden: {
            opacity: 0,
            scale: 0
        },
        visible: {
            opacity: 1,
            scale: 1,
            delay: 0.5,
            transition: {
                duration: 0.5,
                staggerChildren: 0.2,
                delayChildren: 0.5
            }
        }
    }

    const inputVariants = {
        hidden: {
            opacity: 0,
            y: '40px'
        },
        visible: {
            opacity: 1,
            y: '0px',
        }
    }

    return (
        <motion.form class="contact-form" method="POST" name="contactform" data-netlify="true" action="/#/thank-you"
            data-netlify-recaptcha="true"
            variants={formVariants}
            initial="hidden"
            animate="visible"
        >
            <motion.div class="form-group"
                variants={inputVariants}>
                <input type="text" name="name" id="name" minlength="2" class="form-control" placeholder="Name" required />
            </motion.div>

            <motion.div class="form-group"
                variants={inputVariants}>
                <input type="email" name="email" id="email" class="form-control" placeholder="Email" required />
            </motion.div>

            <motion.div class="form-group"
                variants={inputVariants}>
                <input type="tel" name="phone" id="phone" onkeydown="phoneNumberFormatter()" class="form-control" placeholder="Phone" />
            </motion.div>

            <motion.div class="form-group"
                variants={inputVariants}>
                <textarea name="message" id="message" cols="30" rows="10" maxlength="350" class="form-control"
                    placeholder="Message" required></textarea>
            </motion.div>
            <input type="hidden" name="form-name" value="contactform" />

            <motion.div class="recaptcha" data-netlify-recaptcha="true"
                variants={inputVariants}></motion.div>
            <motion.button type="submit" class="submit-button"
                variants={inputVariants}>Send</motion.button>
        </motion.form>
    )
}

export default Form