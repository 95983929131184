import React from 'react'
import Display from '../Display'
import { motion } from 'framer-motion'

function Home() {
    return (
        <motion.main
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>

            <Display />

            <section class="home">
                <h1 class="mainh1">Website Design &amp; Development, Print &amp; Illustration Services</h1>

                <p>
                    David Carrano Graphics and Web Solutions offers Graphic Design services for web and print, custom
                    illustration and image
                    editing, coding and web development to create websites and projects for small businesses and individual
                    projects.
                </p>


                <h2>Services for small businesses</h2>
                <p>
                    Whether your business is just getting started, or if you have been in business for years, having a
                    website
                    will help you grow
                    your business and will be a visual sales representative that works for you to display
                    your branding and
                    message, and be a live resource for your existing customers, 24 hours a day. It will help you get your
                    business found on
                    internet searches,
                    bringing you more leads for your sales funnel, and selling your products and services.
                </p>

                <p>
                    If you do not already have a website for your business, David Carrano Graphics and Web Solutions can
                    help
                    you with everything from branding, displaying your best work, creating secure contact forms, setting up
                    your hosting and domain name, and getting you up and running on time and within your budget.
                </p>

                <p>
                    If you already have a business website that needs some updates, David Carrano Graphics and Web Solutions
                    can help you with web design updates, alignment issues, security updates, search engine optimization
                    updates to help you get found by search engines, custom coding, promotional materials and more.
                </p>



                <h2>Services for developers</h2>
                <p>
                    App and game developers in need of a graphic user interface, custom character design or sprites for
                    animations. Web
                    developers in need of someone to handle some overflow work or user interface for your project. Web
                    designers in need of some interactive features coded into your website.
                </p>



                <h2>Personal web, print, or illustration projects</h2>
                <p>
                    Professionals, photographers, yoga instructors, artists, dancers, whatever you do.. if you want to
                    personalize and enhance your web presence, David Carrano Graphics and Web Solutions can help you get it
                    done.
                </p>

                <p class="call-text">Call or text me today for a free estimate at <span
                    class="phone-number">203-402-9550</span> or you can send me an email message from my <a
                        href="contact.html">Contact page</a>. I look forward to talking with you about your next project.
                </p>



            </section>

        </motion.main>
    )
}

export default Home
