import React from 'react'
import { motion } from 'framer-motion'

function Services() {
    return (
        <motion.main
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >


            <section>
                <h1 class="mainh1">Services</h1>

                <div class="service-box">

                    <h2>Responsive Web Design</h2>

                    <ul class="service-list">
                        <li>Custom coded websites</li>
                        <li>Wordpress websites</li>
                        <li>E-commerce websites</li>
                        <li>Contact forms</li>
                        <li>Registration forms</li>
                        <li>Mobile websites</li>
                        <li>Logo design</li>
                        <li>Custom website graphics</li>
                    </ul>

                </div>

                <div class="service-box">

                    <h2>Print &amp; Web Graphics</h2>

                    <ul class="service-list">
                        <li>Logo design for web and print</li>
                        <li>Vector graphics for print</li>
                        <li>Vector outlines for vinyl cutting</li>
                        <li>Custom graphics for the web</li>
                        <li>Css animations</li>
                        <li>Web advertisements</li>
                        <li>Brochures</li>
                        <li>Cards and invitations</li>
                        <li>Digital image editing</li>
                    </ul>

                </div>

                <div class="service-box">

                    <h2>Custom Illustration</h2>

                    <ul class="service-list">
                        <li>Concept sketches</li>
                        <li>Digital painting</li>
                        <li>Sprites for animation</li>
                        <li>Cartoon drawings</li>
                        <li>Animal portraits</li>
                        <li>App and game assets</li>
                    </ul>

                </div>

            </section>

        </motion.main>

    )
}

export default Services