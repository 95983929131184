import React from 'react'
import { motion } from 'framer-motion'

function Thankyou() {
    return (
        <motion.main
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>


            <section>
                <h1 class="mainh1">Thank you for contacting me!</h1>

                <p>Your message has been sent. I will get back to you within 24 hours.</p>
            </section>

        </motion.main>
    )
}

export default Thankyou