import React from 'react'
import Nav from './Nav'
import Logo from '../assets/images/dclogo200px.png'

function Header() {
    return (
        <header className="header">
            <div class="logo">
                <a href="/" class="logo">
                    <img class="logo-width logo-img" src={Logo} alt="logo" />
                </a>
            </div>
            <Nav />
            <div class="phone"><a href="tel:2034029550">203-402-9550</a></div>
        </header>
    )
}

export default Header