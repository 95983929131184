import React from 'react'
import displayImage1 from '../assets/images/responsive-web-design-display-image.png'
import displayImage2 from '../assets/images/business-cards-logo-brand-png.png'
import displayImage3 from '../assets/images/illustration-display-image-new.png'
import { motion } from 'framer-motion'

function Display() {

    const displayVariants = {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
            delay: 0.5,
            transition: {
                duration: 0.5,
                staggerChildren: 0.4,
                delayChildren: 0.4,
            }
        }
    }

    const displayImageVariants = {
        hidden: {
            x: '200px',
            opacity: 0,
        },
        visible: {
            x: '0',
            opacity: 1,
            delay: 0.5,
            transition: {
                duration: 0.5,

            }
        }
    }


    return (
        <motion.div class="display"
            variants={displayVariants}
            initial="hidden"
            animate="visible"
        >
            <motion.div class="left display-box"
                variants={displayImageVariants}>
                <img src={displayImage1} alt="web design" />
                <div class="box-label">Responsive Web Design</div>
            </motion.div>
            <motion.div class="center display-box"
                variants={displayImageVariants}>
                <img src={displayImage2} alt="graphics for print" />
                <div class="box-label">Print &amp; Web Graphics</div>
            </motion.div>
            <motion.div class="right display-box"
                variants={displayImageVariants}>
                <img src={displayImage3} alt="illustration" />
                <div class="box-label">Custom Illustration</div>
            </motion.div>
        </motion.div >
    )
}

export default Display