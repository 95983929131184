import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

function Nav(props) {

    const navVariants = {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
            delay: 0.5,
            transition: {
                duration: 0.5,
                staggerChildren: 0.2,
                staggerDirection: -1,
                delayChildren: 0.4,
            },
        }
    }

    const navItemVariants = {
        hidden: {
            x: '-200px',
            opacity: 0,

        },
        visible: {
            x: '0px',
            opacity: 1,
            transition: {
                duration: 0.5,
            }
        }
    }

    return (
        <nav>
            <motion.ul
                variants={navVariants}
                initial="hidden"
                animate="visible">
                <motion.li
                    variants={navItemVariants}>
                    <Link to="/">Home</Link>
                </motion.li>
                <motion.li
                    variants={navItemVariants}>
                    <Link to="/about">About</Link>
                </motion.li>
                <motion.li
                    variants={navItemVariants}>
                    <Link to="/services">Services</Link>
                </motion.li>
                <motion.li
                    variants={navItemVariants}>
                    <Link to="/contact">Contact</Link>
                </motion.li>
            </motion.ul>
        </nav>

    )
}

export default Nav