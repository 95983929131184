import React from 'react'
import { motion } from 'framer-motion'

function About() {
    return (
        <motion.main
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>


            <section>
                <h1 class="mainh1">About me</h1>

                <p>I am an artist and web developer with many years of experience using illustration, graphics software and code editors to create digital art and code for interactive web graphics and user interface design, including complete websites, promotional materials, and custom illustration.</p>

                <p>I got my start working for an internet company doing digital image editing, then moved on to creating animations in Flash. Then, started using action script to create interactivity for animations. To display my flash projects, I started building html pages and embedding flash files which created an artistic effect that appealed to me. As a lifelong student of art, I am fluent with most mediums including pencil, colored pencil, pen and ink, acrylic, airbrush and large scale painting for theatrical backdrops, murals, and display. Now I combine my art skills with digital technology to create images for the web as well as graphics and animated page elements.</p>

                <p>I continued building websites for myself and occasionally for small businesses and in 2009 I started building custom coded websites full time with html, css, javascript, jQuery, and local development with WordPress, xampp, and mysql. Though I am mostly self taught, in 2019 I graduated from General Assembly Software Engineering Immersive, where I learned to work with other front and back end technologies including React, Express, Node, EJS, APIs, MongoDB, Ruby on Rails, and PostGresql. Now I am a freelance web developer living in Shelton CT. where I study programming and build websites for small businesses and personal projects.</p>

                <p>I will meet in person for local clients but prefer to work remotely for efficiency. I can meet on zoom or skype and throughout each project, I keep a record of all business correspondence through email. I like to thoroughly understand each clients goals in detail before beginning a project. Once we have a shared understanding I will create a digital contract including the scope of the work which is signed and sent to you via email. Once we have an agreement, I begin working right away. I work full time and I am available during work hours by phone and email everyday throughout the duration of the project.</p>

                <p>I will answer any questions you might have about the project and like to keep communication along the way to make sure we are on track. Please be available to provide approvals as necessary throughout your project so we can keep it moving along smoothly. Some projects might be small and not require much of your attention. However, for larger projects I will add phases of work to the plan and contract. During each phase we should have a brief meeting to discuss the current status, approvals of work already done, and for me to receive any content I need from you to move the project forward such as written content and images if you have them.</p>

                <p>Every project I do is important to me. I am professional and every clients satisfaction is my priority. I will work with you to the best of my ability to give you a product you are happy with and help you make your project a success.</p>

                <p>Please contact me to tell me how I can help you with your next project. I look forward to meeting you.</p>

                <p>David Carrano</p>





            </section>

        </motion.main>
    )
}

export default About