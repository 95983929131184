import React from 'react'
import { motion } from 'framer-motion'
import Form from './Form'

function Contact() {
    return (
        <motion.main
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >


            <section class="contact-section">
                <h1 class="mainh1">Contact</h1>

                <p>You can call or text me during business hours to talk to me now, or leave me your name, email, and a brief description of your project in the form below and I will get back to you within 24 hours.</p>

            </section>

            <Form />



        </motion.main>
    )
}

export default Contact